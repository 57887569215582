<script>
  import './general';
  import './toolbar';

  import Experiment from './experiment.svelte';
  
  import { User } from '../shared/user';
  import { Uploader } from '../shared/uploader';
  import { Message } from '../shared/modular_messages';

  let user = window.User;
  User.onlogin(u=>{
    user=u;
    console.log("onlogin",u);
    user_experiments = get_xp('force refresh');
  });
  User.onlogout(u=>{
    user={};
    user_experiments = get_xp('force refresh');
  });

  let sort_criterion = 'updated_at';
  let order = 'down';
  let filter = '';
  let own_experiments = null;
  function filter_xp(pattern){
    pattern = pattern.replace(/\n/g,'');
    filter = 'editable';
    if (pattern.match(/\w/))
      user_experiments = new Promise(async r=>{
        let xps = await get_xp();
        r(xps.filter(v=>v.name.toLowerCase().includes(pattern.toLowerCase())));
      });
    else{
      user_experiments = get_xp();
      filter = '';
    }
  }
  function update_sort(criterion,flip=true){
    sort_criterion =criterion;
    if (flip) order = (order=='up'?'down':'up');
    user_experiments = get_xp();
  }
  function get_xp(force_refresh=false){
    return new Promise(async r=>{
      if (own_experiments===null||force_refresh) {
        var req = await fetch("/projects_list");
        if (req.status!=200) own_experiments = [];
        else own_experiments = await req.json();
      }
      let mu = x=>x[sort_criterion];
      if (sort_criterion=='updated_at') mu = x=>new Date(x['updated_at']);
      if (order=='down')
        r(own_experiments.sort((w,v)=>mu(v)>mu(w)));
      else
        r(own_experiments.sort((v,w)=>mu(v)>mu(w)));
    });
  }
  let user_experiments = get_xp();

  function clickTry(slug) {
    const link = document.createElement('a');
    link.setAttribute('href', `/r/${slug}/experiment.html?test=true`);
    link.setAttribute('target', '_blank');
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  function duplicateExp(experiment) {
    let link = `/experiments/new?from=${experiment.slug}&name=${experiment.name}_copy`;
    window.location.replace( link );
  }
  function deleteExp(experiment) {
    xp_to_delete = null;
    own_experiments = own_experiments.filter(v=>v.slug!=experiment.slug);
    user_experiments = get_xp();
    Uploader.request_json( {} , '/experiments/'+experiment.slug , 'DELETE' )
      .success( m => {
          Message.log( `Experiment <strong>${experiment.name}</strong> has been successfully deleted` );
          if (m.user) {
              const usersize = window.User.cached_size;
              window.User = JSON.parse( m.user );
              // if (window.User.cached_size != usersize-experiment.cached_size)
              if (window.User.cached_size != usersize)  // Need to compute experiment's size locally
                Message.warning( "Some resources might have been transfered to another experiment, refresh this page for an accurate estimation of the size of the other experiments" );
          }
          User.init( window.User );
      } ).error( m => {
        own_experiments = [experiment, ...own_experiments.filter(v=>v.slug!=experiment.slug)];
        user_experiments = get_xp();
        Message.error(`The experiment named <strong>${experiment.name}</strong> could not be deleted: ${m.error||m.message}`);
      });
  };
  let xp_to_delete = null;

  let xp_hovered = -1;
  let renaming = false;
  let tmp_experiment_slug = false;
</script>

{#if tmp_experiment_slug}
  <div class='tmp'><a href='e.slug'>Reopen last unsaved experiment</a></div>
{/if}

<div class='projectsbrowser'>
  {#if user.name}
    <div class='projects private'>
      <h3>Your projects</h3>
      <div class='filter {filter}' contenteditable=true help="Filter projects by name" on:keyup={e=>filter_xp(e.target.innerText)}></div>
      <div class='sortby'>Sort by: <div class='sorts'>
        <span class='name {sort_criterion=='name'?order:''}' on:click={e=>update_sort('name')}>Name</span>
        <span class='lastedit {sort_criterion=='updated_at'?order:''}' on:click={e=>update_sort('updated_at')}>Last edit</span>
        <span class='size {sort_criterion=='size'?order:''}' on:click={e=>update_sort('size')}>Size</span>
      </div></div>
      <ul>
        {#await user_experiments then list}
          {#each list as experiment, idx}
            <li on:mouseenter={e=>{if(!renaming)xp_hovered=idx;}} on:mouseleave={e=>{if(!renaming)xp_hovered=-1;}}>
              <Experiment {experiment} type='personal' bind:renaming={renaming} hovered={idx==xp_hovered} />
              {#if idx==xp_hovered && !renaming}
                <div class='toolbar'>
                  <div class='tryit' on:click={e=>clickTry(experiment.slug)}>Try it</div>
                  <div class='rename' on:click={e=>renaming=true}>Rename</div>
                  <div class='duplicate' on:click={e=>duplicateExp(experiment)}>Clone</div>
                  <div class='delete' on:click={e=>xp_to_delete=experiment}>Delete</div>
                </div>
              {/if}
            </li>
          {/each}
        {/await}
        {#if user.activated==null}
          <p>Accounts with unverified email addresses are limited to one project. Check your emails ({user.email}) to verify this account.</p>
        {/if}
      </ul>
    </div>
  {/if}

  <div class='projects public'>
    <h3>Start a new project</h3>
    <ul>
      {#await fetch("/master_projects")}
        Loading templates...
      {:then json}
        {#await json.json() then master_experiments}
          {#each master_experiments as experiment}
            <li slug={experiment.slug} help={experiment.description}>
              <a href="/r/{experiment.slug}/experiment.html" target="_blank" help="Try in new tab"> </a>
              <Experiment {experiment} type='template' />
            </li>
          {/each}
        {/await}
      {/await}
    </ul>
  </div>
</div>

{#if xp_to_delete}
  <div class='modulars' on:click={e=>{ if (e.target.classList.contains('modulars')) xp_to_delete=null; }}>
    <div class='container'>
      <div id='closemodular' on:click={()=>xp_to_delete=null}>X</div>
      <div class='modular'>
        <div class='warning'>
          <p>Are you sure you want to delete the experiment named <strong>{xp_to_delete.name}</strong>?</p>
        </div>
        <div style='text-align: center;'>
          <button name='yes' on:click={e=>deleteExp(xp_to_delete)}>Yes</button> 
          <button name='no' on:click={()=>xp_to_delete=null}>No</button>
        </div>
      </div>
    </div>
  </div>  
{/if}
